@import url(~slick-carousel/slick/slick.css);
@import url(~jquery-datetimepicker/build/jquery.datetimepicker.min.css);
* {
  box-sizing: border-box; }

@font-face {
  font-family: "Noto Sans";
  src: url("../shared/fonts/NotoSans-Regular.woff") format("woff"), url("../shared/fonts/NotoSans-Regular.woff2") format("woff2"), url("../shared/fonts/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "franklin-gothic-itc";
  src: url("../shared/fonts/ITC-Franklin-Gothic-LT-Demi-Con-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal; }

body {
  margin: 0;
  padding: 0;
  background-color: #FFF;
  max-width: 1280px;
  margin: 0 auto !important; }

a,
a:visited {
  outline: 0;
  text-decoration: none;
  color: #000; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0; }

p {
  margin: 0; }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }

::-ms-clear,
::-ms-reveal {
  display: none; }

img {
  border: 0;
  vertical-align: middle;
  user-select: none; }

table,
thead,
tbody,
tr,
td,
th {
  border: none;
  border-collapse: collapse;
  padding: 0;
  margin: 0; }

button,
input,
select,
textarea,
div {
  outline: none;
  border-radius: 0; }

dd,
dt {
  margin: 0;
  padding: 0; }

iframe {
  border-width: 0;
  display: block;
  width: 0;
  height: 0; }

sub {
  position: relative;
  top: -5px;
  vertical-align: top; }

.-full-width {
  width: 100%; }

.-center-below-tablet-l {
  text-align: center; }
  @media (min-width: 1023px) {
    .-center-below-tablet-l {
      text-align: left; } }

.-display-above-tablet--ib {
  display: none; }
  @media (min-width: 960px) {
    .-display-above-tablet--ib {
      display: inline-block; } }

.-display-below-tablet--ib {
  display: inline-block; }
  @media (min-width: 960px) {
    .-display-below-tablet--ib {
      display: none; } }

.-display-below-phone-l--i {
  display: inline; }
  @media (min-width: 630px) {
    .-display-below-phone-l--i {
      display: none; } }

.-display-above-phone-l--i {
  display: none; }
  @media (min-width: 630px) {
    .-display-above-phone-l--i {
      display: inline; } }

.-display-above-desktop-small--i {
  display: none; }
  @media (min-width: 950px) {
    .-display-above-desktop-small--i {
      display: inline; } }

.common_sections {
  position: relative;
  padding-top: 20px; }
  .common_sections:after {
    content: " ";
    display: block;
    clear: both; }
  .common_sections.st2 {
    padding-top: 20px; }
  .common_sections.st3 {
    padding-top: 10px; }
  .common_sections_line {
    border-bottom: 1px solid #ebebeb;
    padding-top: 20px; }

.common_form-textarea {
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  padding: 10px 14px;
  resize: none;
  margin: 2px 0;
  font-size: 16px;
  line-height: 18px;
  border: 1px solid #ebebeb; }

.common_form-name {
  width: 100%;
  font-size: 12px;
  padding-bottom: 14px;
  @apply font-fga font-medium; }
  @media (min-width: 960px) {
    .common_form-name {
      line-height: 18px; } }

.common_form-name.last {
  margin-bottom: 10px; }

.common_form-ipt, .common_form-ipt-container {
  display: block;
  width: 100%;
  padding: 0 15px;
  font-size: 16px;
  border-radius: 4px;
  height: 50px;
  line-height: 50px;
  border: solid 1px; }
  .common_form-ipt::placeholder, .common_form-ipt-container::placeholder {
    font-size: 12px;
    letter-spacing: -0.12px;
    color: #aaaaaa;
    @apply font-fga font-medium; }

.common_form-ipt.small, .common_form-ipt.middle {
  display: inline-block;
  width: 30px;
  text-align: center;
  border: none;
  padding: 0 2px;
  height: 43px;
  line-height: 43px;
  position: relative; }

.common_form-ipt.middle {
  text-align: left;
  width: 48px; }

.common_form-ipt-container {
  position: relative;
  padding: 0;
  background-color: #fff;
  border: 0; }

.common_form-select {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
  border-radius: 0;
  border: solid 1px; }

.common_form-place-hold {
  border: 1px solid #2c2c2c;
  border-radius: 4px;
  width: 100%;
  font-size: 12px;
  color: #aaaaaa;
  line-height: 50px;
  padding: 0 12px;
  background: url("../assets/images/svg/arrow_black.svg") no-repeat center right 12px;
  @apply font-fga font-medium; }
  @media (min-width: 768px) {
    .common_form-place-hold {
      flex-basis: 48%;
      height: 50px; } }

.common_form-place-hold .icon {
  position: absolute;
  right: 16px;
  top: 50%;
  margin-top: -10px;
  color: #000; }

.common_form-label {
  display: inline-block;
  position: relative;
  padding: 0px 20px 0px 30px;
  cursor: pointer; }
  .common_form-label .common_form-type {
    padding-left: 10px; }

.horilized .common_form-label {
  display: block; }

.common_form-radio {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute; }

.common_form-type {
  font-size: 12px;
  line-height: 1.1;
  font-weight: 500px;
  @apply font-fga; }

.common_form-type.st2 {
  font-size: 14px;
  line-height: 28px; }

.common_form-out-box {
  width: 25px;
  height: 25px;
  position: absolute;
  border: solid 1px;
  top: 0;
  left: 0;
  background-color: #fff; }
  .common_form-out-box.round {
    border-radius: 15px; }
  .common_form-out-box.focusedOn {
    border-color: rgba(131, 192, 253, 0.5); }

.common_form-inner-box {
  width: 23px;
  height: 23px;
  background-color: #d64529;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.35s; }
  .common_form-inner-box.round {
    border-radius: 10px; }

.common_form-radio:checked + .common_form-out-box {
  border-color: black; }

.common_form-radio:checked + .common_form-out-box .common_form-inner-box {
  opacity: 1; }

.common_form-hidden-ipt {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  padding: 0;
  border: 0;
  overflow: hidden; }

.common_form-line {
  height: 2px;
  background-color: #2c2c2c;
  margin: 40px auto 10px; }
  @media (min-width: 960px) {
    .common_form-line {
      max-width: 500px;
      margin: 30px 0 10px; } }

.common_form-line.st2 {
  margin-top: 25px; }

.common_form__process {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 8px;
  z-index: 1; }

.common_form__bar {
  position: absolute;
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fa6600;
  transition: all 0.9s ease-in-out; }
  .common_form__bar.min {
    width: 33%; }
  .common_form__bar.active {
    width: 66%; }
  .common_form__bar.max {
    width: 100%; }

.common-cta_btn {
  transition: all 0.5s ease-in-out;
  position: relative;
  display: inline-block;
  font-family: ff(Docb);
  font-size: 16px;
  line-height: 18px;
  min-height: 48px;
  text-transform: uppercase;
  color: #000;
  background-color: #fa6600;
  cursor: pointer;
  text-align: left;
  padding: 15px 43px 15px 20px;
  border: none;
  letter-spacing: 1.6px; }
  .common-cta_btn img,
  .common-cta_btn .icon {
    position: absolute;
    width: 18px;
    top: 50%;
    transform: translateY(-50%);
    right: 15px; }
  .common-cta_btn:hover {
    background-color: #d3561a; }
  .common-cta_btn.blocked {
    display: block;
    text-align: center; }
  .common-cta_btn.bordered {
    min-height: 37px;
    border: solid 2px #000;
    background-color: transparent; }
    .common-cta_btn.bordered:hover {
      color: #fff;
      background-color: #000; }
  .common-cta_btn.dark {
    background-color: #fff; }
  .common-cta_btn.dark.bordered {
    color: #fff;
    background-color: transparent;
    border: solid 2px #fff; }
    .common-cta_btn.dark.bordered:hover {
      color: #000;
      background-color: #fff; }

p.error,
div.error {
  display: none;
  font-size: 14px;
  line-height: 18px;
  padding-top: 5px; }

.common_sections.form_error {
  color: #a8000a; }
  .common_sections.form_error .common_form-place-hold {
    border: 0; }

.common_sections.form_error .error {
  display: block; }

.common_sections.form_error.special .error,
.common_sections.form_error .error.special {
  display: none; }

.common_sections.form_error.special .error.special {
  display: block; }

.common_form-ipt-container .common_form-place-hold {
  color: #7e7e7e; }

.common_form-ipt-container .common_form-place-hold.used {
  color: #000; }

.common_sections.form_error .common_form-ipt,
.common_sections.form_error .common_form-ipt-container,
.common_sections.form_error .common_form-ipt-container .common_form-place-hold,
.common_sections.form_error
.common_form-ipt-container
.common_form-place-hold.used {
  color: #a8000a;
  border-color: #a8000a; }

.common_sections.form_error .common_form-ipt-container {
  border: solid 1px; }

.common_sections.form_error ::-webkit-input-placeholder {
  color: #a8000a; }

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder {
  font-size: 14px;
  font-family: ff(Din);
  color: #7e7e7e; }

input:-moz-placeholder,
input::-moz-placeholder {
  opacity: 1; }

.common_sections.form_error .common_form-textarea {
  border-color: #a8000a; }

.common_form-radio-container {
  padding-top: 0px; }

.common_sections.form_error .common_form-radio-container {
  color: #a8000a; }

.dn {
  display: none; }

.hidden-small {
  display: none; }
  @media (min-width: 960px) {
    .hidden-small {
      display: block; } }

.hidden-big {
  display: block; }
  @media (min-width: 960px) {
    .hidden-big {
      display: none; } }

.pt10 {
  padding-top: 10px; }

.mc {
  margin-left: auto;
  margin-right: auto; }

.ml5 {
  margin-left: 5px; }

.ml10 {
  margin-left: 10px; }

.pr {
  position: relative; }

.cp {
  cursor: pointer; }

@keyframes arrowToRight {
  50% {
    opacity: 0;
    transform: translateX(55px); }
  70% {
    transform: translateX(-30px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes arrowToLeft {
  50% {
    transform: translateX(-10px); }
  100% {
    transform: translateX(0); } }

@keyframes arrowToBottom {
  40% {
    opacity: 0;
    transform: translateY(60%); }
  50% {
    transform: translateY(-150%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

@keyframes bigger {
  0% {
    opacity: 0;
    transform: scaleY(0.001); }
  100% {
    opacity: 1;
    transform: scaleY(1); } }

@media (min-width: 740px) {
  .show-lt-desktop {
    display: none; } }

.show-gt-desktop {
  display: none; }
  @media (min-width: 740px) {
    .show-gt-desktop {
      display: block; } }

label.common_form-name {
  display: block; }

.common_sections.special .common_form-name {
  font-size: 16px; }

button[type="submit"]:disabled::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: not-allowed; }

.org-colored {
  /* stylelint-disable-next-line declaration-no-important */
  color: #fa6600 !important; }

.uppercase-text {
  text-transform: uppercase; }

.underLined {
  text-decoration: underline; }

.icon {
  display: inline-block;
  stroke-width: 0;
  stroke: #000;
  fill: currentColor;
  width: 18px;
  height: 18px;
  vertical-align: middle; }

.icon-chevron-left {
  transform: rotateY(180deg); }

.icon-hd-logo {
  width: 44px;
  height: 36px; }

@media (min-width: 960px) {
  .black__icon .icon-hd-logo {
    filter: brightness(10) invert(1); } }

.icon-twitter {
  width: 25px;
  height: 20px; }

.icon-instagram {
  width: 22px;
  height: 22px; }

.icon-facebook {
  width: 13px;
  height: 25px; }

.icon-linkedin {
  position: relative;
  top: -.2em; }

.icon-youtube {
  width: 24px;
  height: 24px; }

.footer {
  font-family: "Franklin Gothic ATF";
  background-color: #000;
  text-align: center;
  color: #7f7f7f; }
  .footer__warp {
    @apply font-fgs;
    display: flex;
    padding-top: 40px;
    padding-bottom: 14px;
    justify-content: center; }
    .footer__warp p {
      font-size: 15px;
      line-height: 21px;
      letter-spacing: 0.75px;
      text-transform: uppercase; }
    @media (max-width: 768px) {
      .footer__warp {
        flex-wrap: wrap;
        flex-direction: column; }
        .footer__warp p {
          width: 100%; } }
  .footer_title {
    color: #fff; }
  .footer_color {
    color: #FA6600; }
  .footer__share-container {
    padding-top: 23px;
    padding-bottom: 20px; }
    .footer__share-container a {
      display: inline-block;
      padding: 0 10px; }
      .footer__share-container a img {
        height: 23px; }
        .footer__share-container a img:last-child {
          height: 20px; }
      .footer__share-container a img
.footer__share-container a img.footer__icon-twitter {
        height: 21px; }
  .footer_links-container a {
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.55px;
    color: #989898;
    text-transform: uppercase;
    opacity: 1; }
  @media (max-width: 768px) {
    .footer_links-container {
      transform: scale(0.9); } }
  .footer_register-container {
    padding-top: 17px;
    padding-bottom: 36px;
    font-weight: 500;
    font-size: 12px;
    font-family: Franklin Gothic ATF;
    line-height: 13px;
    letter-spacing: 0px;
    color: #989898;
    opacity: 1; }
  .footer .common_form-ipt-container {
    margin: 0 auto;
    border: none;
    text-align: center;
    background-color: transparent;
    padding: 0;
    position: relative;
    max-width: 230px; }
    .footer .common_form-ipt-container .common_form-place-hold {
      text-align: left; }
  .footer .common_form-select {
    position: relative;
    min-width: 230px;
    -webkit-appearance: none;
    height: 100%;
    width: 100%;
    background-size: 5px;
    border: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0; }
  .footer .common_sections {
    padding-top: 20px; }
  .footer .common_form-place-hold {
    border: 1px solid white;
    border-radius: 4px;
    width: 100%;
    font-size: 13px;
    text-transform: uppercase;
    color: white;
    height: 45px;
    line-height: 45px;
    padding: 0 17px;
    letter-spacing: -0.13px;
    background: url("../assets/images/svg/arrow_white.svg") no-repeat center right 12px;
    background-size: 12px;
    font-family: franklin-gothic-itc, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400; }
    .footer .common_form-place-hold .icon {
      position: relative;
      color: #fa6600;
      right: auto;
      top: auto;
      margin: -5px 0 0 5px; }
  .footer .tf {
    text-align: left; }

@media (min-width: 960px) {
  .forForms .footer {
    width: 66%;
    left: 34%; } }

.navigation {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff; }
  .navigation img {
    width: 45px;
    height: 37px; }

.header {
  max-width: 915px;
  margin: 0 20px;
  padding-top: 50px;
  background: #fff;
  text-align: center;
  padding-right: 20px;
  padding-left: 20px; }
  @media (min-width: 960px) {
    .header {
      padding-right: 143px;
      padding-left: 143px;
      margin: 0 auto; } }
  .header__title {
    line-height: 46px;
    padding-bottom: 15px;
    @apply text-center font-fga font-bold text-4xl; }
  .header__text {
    font-size: 16px;
    letter-spacing: -0.4px;
    text-indent: 16px;
    padding-bottom: 15px;
    @apply text-center font-fga font-normal text-base border-form; }
    .header__text:last-child {
      padding-bottom: 0px; }
    @media (min-width: 960px) {
      .header__text {
        max-width: none;
        text-indent: 48px; } }
  .header__steps {
    position: relative;
    display: flex;
    margin-bottom: 20px; }
    .header__steps:last-child {
      margin-bottom: 0px; }
    @media (min-width: 960px) {
      .header__steps {
        float: left;
        width: 33.33%;
        max-width: calc(437px / 3);
        text-align: center;
        margin: 0;
        display: block; } }
    .header__steps-box:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 960px) {
      .header__steps-box {
        width: 629px;
        margin: 30px auto 0px;
        padding-bottom: 24px;
        border-bottom: 1px solid #eeeceb;
        display: flex;
        justify-content: center; } }
    .header__steps-circle {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      margin-right: 15px;
      background-color: #fa6600;
      border-radius: 50%;
      font-size: 16px;
      font-weight: 500;
      z-index: 1;
      @apply font-fga; }
    .header__steps-text {
      font-size: 12px;
      line-height: 30px;
      font-weight: 500;
      @apply font-fga; }
      @media (min-width: 960px) {
        .header__steps-text {
          padding-top: 12px;
          line-height: 17px;
          display: block; } }
    .header__steps::before {
      content: "";
      position: absolute;
      width: 2px;
      height: 40px;
      left: 14px;
      top: 20px;
      background-color: #fa6600; }
      @media (min-width: 960px) {
        .header__steps::before {
          height: 2px;
          width: 50%;
          top: 13px;
          left: 50%; } }
    @media (min-width: 960px) {
      .header__steps::after {
        content: "";
        position: absolute;
        height: 2px;
        width: 50%;
        top: 13px;
        left: 0;
        background-color: #fa6600; } }
    .header__steps:last-child::before {
      display: none; }
    .header__steps:first-child::after {
      display: none; }

.bikeList {
  padding: 16px 20px 0;
  max-width: 915px;
  margin: 0 20px;
  background: #fff; }
  @media (min-width: 960px) {
    .bikeList {
      padding: 16px 143px 0;
      margin: 0 auto; } }
  .bikeList__body-text {
    font-size: 12px;
    color: #000;
    @apply font-fga font-normal; }
  .bikeList__body-desc {
    font-size: 12px;
    line-height: 16px;
    color: #000;
    font-weight: 400px;
    padding-top: 16px;
    @apply font-fga; }
    .bikeList__body-desc:last-child {
      padding-top: 0; }
  .bikeList__title {
    font-size: 12px;
    line-height: 16px;
    margin-top: 16px;
    @apply font-fga font-medium; }
  .bikeList__sub {
    font-family: ff(Din);
    font-size: 14px;
    line-height: 24px;
    padding: 15px 0 18px; }
    .bikeList__sub-right {
      display: none; }
      @media (min-width: 960px) {
        .bikeList__sub-right {
          float: right;
          display: inline-block; } }
  .bikeList__select {
    display: none;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    padding: 17px 20px;
    background-color: #fff;
    color: #000;
    position: relative;
    cursor: pointer; }
    .bikeList__select:after {
      content: " ";
      display: block;
      clear: both; }
    .bikeList__select .icon-arrow-down {
      color: #000;
      float: right; }
    .bikeList__select-out {
      position: relative; }
      @media (min-width: 960px) {
        .bikeList__select-out {
          display: none; } }
    .bikeList__select-list {
      background-color: #fff;
      color: #000;
      font-family: ff(Din);
      position: absolute;
      width: 100%;
      z-index: 2;
      display: none;
      max-height: 75vh;
      overflow-y: scroll; }
      .bikeList__select-list.open {
        display: block; }
    .bikeList__select-li {
      font-size: 18px;
      line-height: 55px;
      width: calc(100% - 2px);
      padding: 0 15px 0 60px;
      cursor: pointer;
      position: relative; }
      .bikeList__select-li .icon {
        display: none;
        float: right;
        margin-top: 17px; }
      .bikeList__select-li.active {
        background-color: #fa6600;
        font-family: ff(Dinb); }
        .bikeList__select-li.active .icon {
          display: block; }
      .bikeList__select-li::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        top: 0;
        left: 0; }
    .bikeList__select.open .icon {
      transform: rotate(180deg); }
  .bikeList__container {
    padding: 15px 0;
    width: calc(100% + 16px); }
    .bikeList__container:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 960px) {
      .bikeList__container {
        width: calc(100% + 20px); } }
  .bikeList__card {
    float: left;
    width: calc((100% - 32px) / 2);
    margin-right: 16px;
    margin-bottom: 15px;
    position: relative; }
    @media (min-width: 960px) {
      .bikeList__card {
        width: calc((100% - 80px) / 4);
        margin-right: 20px;
        margin-bottom: 18px; } }
    .bikeList__card:last-child {
      margin-bottom: 0px; }
    .bikeList__card::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      left: 0;
      top: 0;
      opacity: 0.35;
      transition: opacity 0.35s ease-in-out;
      cursor: pointer; }
    .bikeList__card-name {
      width: calc(100% - 20px); }
    .bikeList__card-title {
      padding: 12px 7px;
      color: #fff;
      background-color: #fa6600;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: -0.11px;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      @apply font-fga;
      transition: all 0.35s ease-in-out; }
      .bikeList__card-title:after {
        content: " ";
        display: block;
        clear: both; }
      .bikeList__card-title .icon-arrow-down {
        height: 10px;
        width: 10px;
        transition: all 0.35s ease-in-out;
        color: #fff;
        float: right;
        position: absolute;
        right: 7px; }
    .bikeList__card.others::after {
      background-color: #000; }
    .bikeList__card.active::after {
      opacity: 0; }
    .bikeList__card.active .bikeList__card-title {
      background-color: #fa6600;
      color: #000; }
      .bikeList__card.active .bikeList__card-title .icon-arrow-down {
        transform: rotate(180deg); }
  .bikeList__imgBox {
    position: relative;
    background-color: #fff;
    padding-bottom: 78.5%;
    border: 1px solid #eeeceb; }
    .bikeList__imgBox:last-child {
      background-color: transparent; }
  .bikeList__img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0; }
  .bikeList__info {
    margin-bottom: 15px;
    padding: 10px 13px 23px 20px;
    color: #000;
    background-color: #fff;
    border-top: solid 8px #fa6600;
    float: left;
    width: calc(100% - 16px);
    position: relative;
    transform-origin: left top;
    animation: bigger 0.45s ease-in-out; }
    .bikeList__info:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 960px) {
      .bikeList__info {
        width: calc(100% - 20px);
        padding: 10px 10px 10px 16px; } }
    .bikeList__info::before {
      content: "";
      position: absolute;
      top: -15px;
      left: 0;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 8px solid #fa6600; }
    .bikeList__info.first::before {
      left: 20%; }
      @media (min-width: 960px) {
        .bikeList__info.first::before {
          left: 11%; } }
    .bikeList__info.second::before {
      left: 75%; }
      @media (min-width: 960px) {
        .bikeList__info.second::before {
          left: 37%; } }
    .bikeList__info.third::before {
      left: 20%; }
      @media (min-width: 960px) {
        .bikeList__info.third::before {
          left: auto;
          right: 37%; } }
    .bikeList__info.forth::before {
      left: 75%; }
      @media (min-width: 960px) {
        .bikeList__info.forth::before {
          left: auto;
          right: 11%; } }
    .bikeList__info-close {
      float: right;
      position: relative; }
      .bikeList__info-close::after {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        top: 0;
        left: 0;
        cursor: pointer; }
      .bikeList__info-close .icon-close {
        width: 24px;
        height: 24px; }
    .bikeList__info-img {
      display: none; }
      @media (min-width: 960px) {
        .bikeList__info-img {
          display: block;
          float: left;
          margin-top: 20px; } }
    @media (min-width: 960px) {
      .bikeList__info-box {
        padding-left: 20px;
        float: left;
        width: calc(100% - 174px); }
        .bikeList__info-box:after {
          content: " ";
          display: block;
          clear: both; } }
    .bikeList__info-title {
      font-family: ff(Am);
      font-size: 26px;
      line-height: 35px;
      padding-top: 10px; }
      @media (min-width: 960px) {
        .bikeList__info-title {
          font-size: 24px;
          line-height: 42px; } }
      .bikeList__info-title sup {
        font-size: 14px; }
    .bikeList__info-text {
      font-family: ff(Din);
      font-size: 14px;
      line-height: 18px;
      padding: 3px 0 15px; }
      @media (min-width: 960px) {
        .bikeList__info-text {
          max-width: 313px; } }
    @media (min-width: 960px) {
      .bikeList__info-btn {
        float: right; } }
    .bikeList__info-btn .common-cta_btn {
      min-width: 162.5px; }
      @media (min-width: 960px) {
        .bikeList__info-btn .common-cta_btn {
          min-width: 211px; } }

.googleMap {
  position: relative;
  margin: 0 auto;
  max-width: 915px;
  padding: 0 20px;
  background: #fff;
  color: #000; }
  @media (min-width: 960px) {
    .googleMap {
      padding: 0 143px; } }
  .googleMap__textBox {
    border-top: 1px solid #eeeceb; }
    .googleMap__textBox-title {
      padding-top: 16px;
      width: 100%;
      font-size: 12px;
      @apply font-fga font-medium; }
    .googleMap__textBox-subtitle {
      font-size: 12px;
      margin-top: 12px;
      @apply font-fga font-normal; }
    .googleMap__textBox-text, .googleMap__textBox-changeText {
      padding-top: 8px;
      font-size: 12px;
      line-height: 18px; }
  .googleMap__out {
    background-color: #eeeceb; }
  .googleMap__auBox {
    padding: 30px 0 10px; }
    .googleMap__auBox .common_form-type {
      @apply font-fga; }
    .googleMap__auBox .common_form-radio-container {
      padding-top: 16px; }
  .googleMap__textBox-text, .googleMap__textBox-changeText {
    @apply font-fga;
    font-size: 12px;
    line-height: 18px; }
  .googleMap__clearBox:after {
    content: " ";
    display: block;
    clear: both; }
  .googleMap__dealerBox {
    padding: 20px 0; }
    .googleMap__dealerBox-change {
      color: #fa6600;
      font-weight: 500; }
    .googleMap__dealerBox .common-cta_btn {
      margin-top: 20px;
      display: inline-block;
      border: 1.5px solid black;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 16px;
      min-height: 36px;
      width: 100%;
      text-align: center;
      letter-spacing: -0.16px;
      padding: 10px 19px;
      @apply font-fgs font-normal; }
      @media (min-width: 768px) {
        .googleMap__dealerBox .common-cta_btn {
          min-width: 132px;
          width: fit-content;
          padding: 10px 19px;
          line-height: 16px; } }
    .googleMap__dealerBox-title, .googleMap__dealerBox-change {
      font-size: 12px;
      line-height: 18px;
      @apply font-fga; }
    .googleMap__dealerBox-btn .common-cta_btn {
      background: #fa6600;
      color: white;
      border: 0; }
    .googleMap__dealerBox-btnOut {
      width: 151px; }
      @media (min-width: 768px) {
        .googleMap__dealerBox-btnOut {
          float: left;
          width: 300px;
          padding: 10px 0 0 85px; } }
  .googleMap__dealerCard {
    margin: 20px 0 20px;
    width: 180px;
    background-color: #fff;
    color: #000; }
    @media (min-width: 768px) {
      .googleMap__dealerCard {
        float: left;
        width: 300px;
        margin-top: 10px;
        padding: 0px 75px 0px 0px;
        margin-bottom: 0; } }
    .googleMap__dealerCard-title {
      @apply font-fga;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500; }
    .googleMap__dealerCard-text {
      padding-top: 8px;
      @apply font-fga;
      font-size: 12px;
      line-height: 18px; }
    .googleMap__dealerCard-btn {
      display: none; }
      @media (min-width: 768px) {
        .googleMap__dealerCard-btn {
          display: block; } }
  .googleMap__mapBox {
    padding: 20px 0 40px; }
    .googleMap__mapBox-text {
      @apply font-fga;
      font-size: 14px;
      line-height: 22px; }
  .googleMap__bet {
    padding: 10px 0;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1.14px; }
    @media (min-width: 768px) {
      .googleMap__bet {
        float: left;
        width: 60px;
        line-height: 40px; } }
  .googleMap__searchBox {
    margin-top: 5px;
    position: relative; }
    @media (min-width: 768px) {
      .googleMap__searchBox {
        float: left;
        width: calc((136% - 60px) / 2); } }
    .googleMap__searchBox-out {
      max-width: 664px; }
      .googleMap__searchBox-out:after {
        content: " ";
        display: block;
        clear: both; }
      @media (min-width: 768px) {
        .googleMap__searchBox-out .common-cta_btn {
          margin-top: 5px;
          float: left;
          width: calc((100% - 60px) / 2); } }
    .googleMap__searchBox input {
      padding-left: 35px; }
  .googleMap__loader {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px; }
  .googleMap__locator {
    position: absolute;
    top: 15px;
    left: 10px; }
  .googleMap__container {
    position: relative;
    display: none;
    margin-top: 10px; }
    @media (min-width: 768px) {
      .googleMap__container {
        height: 350px; } }
  .googleMap__map {
    padding-bottom: 100%;
    background-color: #eeeceb; }
    @media (min-width: 768px) {
      .googleMap__map {
        padding-bottom: 0;
        width: 340px;
        height: 350px;
        float: right; } }
  .googleMap__info-box {
    background-color: #eee; }
    @media (min-width: 768px) {
      .googleMap__info-box {
        width: calc(100% - 340px);
        height: 350px;
        float: right;
        overflow: hidden;
        overflow-y: scroll; } }
  .googleMap__info-list {
    cursor: pointer;
    display: block;
    width: 100%;
    border-top: solid 1px #2c2c2c;
    padding: 30px 22px 23px 14px;
    color: #000; }
    .googleMap__info-list:after {
      content: " ";
      display: block;
      clear: both; }
    @media (min-width: 768px) {
      .googleMap__info-list {
        border-top: none;
        border-bottom: solid 1px #7e7e7e;
        padding: 13px 15px 15px 15px; } }
    .googleMap__info-list.active {
      background-color: #eb601d;
      border: solid 2px #eb601d; }
      @media (min-width: 768px) {
        .googleMap__info-list.active {
          border: solid 1px #eb601d; } }
      .googleMap__info-list.active .googleMap__detail-title,
      .googleMap__info-list.active .googleMap__distance {
        color: black; }
      .googleMap__info-list.active .googleMap__img-count {
        background: url("../assets/images/png/Map-Pin-3.png") no-repeat;
        background-size: cover;
        color: #fff; }
      .googleMap__info-list.active .org-colored {
        color: white !important; }
  .googleMap__img-box {
    float: left;
    position: relative; }
  .googleMap__img-count {
    width: 30px;
    height: 47.1px;
    background: url("../assets/images/png/Map-Pin.png") no-repeat;
    background-size: cover;
    line-height: 28px;
    font-size: 14px;
    text-align: center;
    color: #000;
    @apply font-fga;
    font-weight: 600; }
  .googleMap__detail-box {
    position: relative;
    float: right;
    width: calc(100% - 60px); }
  .googleMap__detail-title {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    @apply font-fga;
    text-transform: uppercase;
    letter-spacing: 0px; }
  .googleMap__detail-address, .googleMap__detail-phone {
    font-size: 11px;
    line-height: 19px;
    margin-top: 4px;
    max-width: 140px;
    @apply font-fga; }
  .googleMap__distance {
    font-size: 10px;
    line-height: 20px;
    @apply font-fga;
    margin-top: 5px; }
  .googleMap__detail-phone {
    margin-top: 10px; }
  .googleMap__isShowBox {
    font-size: 14px;
    line-height: 22px;
    @apply font-fga;
    margin-top: 5px; }
  .googleMap__showMore {
    font-size: 15px;
    line-height: 23px;
    margin-top: 23px;
    @apply font-fga; }

@media (min-width: 768px) {
  .el_GR .googleMap__dealerBox-btnOut {
    padding: 30px 0 0 85px; } }

@media (min-width: 960px) {
  .common__book-form {
    margin: 0; } }

.no-show {
  display: none; }

.formbuilder_link {
  color: #d64529 !important;
  display: inline-block;
  position: relative; }
  .formbuilder_link_inner {
    text-decoration: underline; }
  .formbuilder_link_overlay:before {
    bottom: -26px;
    border-bottom: 20px solid black;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -20px;
    z-index: 999; }

.formModel {
  background: #fff;
  padding: 0 20px;
  margin: 0 auto;
  max-width: 915px;
  @apply font-fga; }
  @media (min-width: 960px) {
    .formModel {
      padding: 0 143px;
      clear: both; } }
  .formModel-formContainer {
    background: #2c2c2c;
    margin: 35px 20px;
    min-height: calc(100vh - 324px); }
    @media (min-width: 768px) {
      .formModel-formContainer {
        width: 100%;
        max-width: 915px;
        margin-left: auto;
        margin-right: auto;
        min-height: calc(100vh - 333px); } }
  .formModel_map_container {
    border: 0 !important;
    padding: 0 !important; }
  .formModel_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    position: absolute;
    height: 100%;
    right: 60px; }
    .formModel_loader img {
      padding-bottom: 0px; }
  .formModel-radio {
    /* checkbox aspect */
    /* checked mark aspect */ }
    .formModel-radio:first-child {
      margin-right: 25px; }
    .formModel-radio [type="radio"]:not(:checked),
    .formModel-radio [type="radio"]:checked {
      position: absolute;
      left: 0;
      opacity: 0.01; }
    .formModel-radio [type="radio"]:not(:checked) + label,
    .formModel-radio [type="radio"]:checked + label {
      position: relative;
      padding-left: 35px;
      font-size: 12px;
      line-height: 25px;
      height: 25px;
      display: block;
      cursor: pointer;
      @apply font-fga font-normal; }
    .formModel-radio [type="radio"]:not(:checked) + label:before,
    .formModel-radio [type="radio"]:checked + label:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 25px;
      height: 25px;
      border: 1px solid #2c2c2c;
      background: #fff;
      -webkit-transition: all 0.275s;
      transition: all 0.275s; }
    .formModel-radio [type="radio"]:checked + label:after {
      content: "";
      position: absolute;
      font-size: 1.375em;
      top: 1px;
      left: 1px;
      background: #d64529;
      height: 23px;
      width: 23px;
      line-height: 0;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
  .formModel-phone-container, .formModel-addr-container {
    display: none; }
    .formModel-phone-container .formdemo_row:first-child, .formModel-addr-container .formdemo_row:first-child {
      padding-top: 0; }
  .formModel_error .formdemo-customer-dropDown {
    border: 1px solid #a8000a;
    color: #a8000a; }
  .formModel_error .formdemo__errorMsg {
    display: block; }
  .formModel__errorMsg {
    font-size: 12px;
    color: #a8000a;
    padding-top: 12px;
    display: none;
    @apply font-fga font-normal; }
  .formModel-header {
    width: 100%;
    @apply text-center font-fga; }
    .formModel-header h1 {
      line-height: 46px;
      padding-bottom: 15px; }
    .formModel-header p {
      letter-spacing: -0.4px; }
  .formModel_select {
    -webkit-appearance: none;
    border: 1px solid #2c2c2c;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    background-size: 5px;
    border: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0; }
  .formModel-recaptcha {
    margin-top: 20px; }
  .formModel-location, .formModel-date {
    flex-basis: 48%;
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    position: relative; }
  .formModel-formWrapper {
    width: 100%;
    background: white;
    position: relative;
    padding-top: 50px;
    padding-bottom: 60px;
    padding-right: 20px;
    padding-left: 20px;
    @apply flex items-center flex-col; }
    @media (min-width: 768px) {
      .formModel-formWrapper {
        min-height: unset;
        max-width: 1280px;
        padding-right: 15.8%;
        padding-left: 15.8%; } }
  .formModel_row {
    flex-wrap: wrap; }
    .formModel_row.formdemo_error {
      padding-bottom: 0px; }
    @media (min-width: 768px) {
      .formModel_row {
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
        justify-content: space-between;
        flex-wrap: nowrap; } }
    .formModel_row-last {
      padding-bottom: 20px; }
    .formModel_row:first-child {
      padding-top: 20px; }
    .formModel_row_nopadding {
      padding: 0 !important; }
  .formModel_cell {
    flex-basis: 48%;
    flex-wrap: wrap; }
    .formModel_cell-large {
      width: 100%; }
  .formModel_input::placeholder, .formModel_search::placeholder {
    font-size: 12px;
    letter-spacing: -0.12px;
    color: #aaaaaa;
    @apply font-fga font-medium; }
  .formModel_input.error, .formModel_search.error {
    border: 1px solid #a8000a;
    color: #a8000a; }
    .formModel_input.error::placeholder, .formModel_search.error::placeholder {
      color: #a8000a; }
  .formModel-moto {
    margin-top: 16px;
    width: 100%;
    font-size: 12px;
    @apply font-fga font-medium; }
  .formModel_search {
    border: 1px solid #2c2c2c;
    border-radius: 4px;
    height: 50px;
    line-height: 50px;
    padding: 0 12px;
    width: 100%; }
    .formModel_search_logo {
      width: 40px;
      height: 100%;
      position: absolute;
      right: 0;
      display: flex;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid #2c2c2c;
      background-color: white;
      justify-content: center;
      align-items: center;
      border: 1px solid #2c2c2c;
      cursor: pointer; }
      .formModel_search_logo img {
        padding: 0;
        width: 13px;
        height: auto; }
  .formModel-overlay {
    top: -99999px;
    position: absolute;
    left: 0px;
    background-color: black;
    width: 100%;
    color: white;
    padding: 15px 20px;
    z-index: 998;
    margin-top: 5px;
    font-size: 12px;
    @apply font-fga font-normal; }
    @media (min-width: 768px) {
      .formModel-overlay {
        width: 600px; } }
    .formModel-overlay a {
      color: #d64529;
      display: inline-block;
      position: relative;
      text-decoration: underline; }
  .formModel_input {
    border: 1px solid #2c2c2c;
    border-radius: 4px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 12px;
    margin-top: 20px; }
    @media (min-width: 768px) {
      .formModel_input {
        width: 100%;
        margin-top: 0px; }
        .formModel_input-large {
          flex-basis: 470px; } }
  .formModel-dropDown {
    margin-top: 20px;
    position: relative; }
    @media (min-width: 768px) {
      .formModel-dropDown {
        flex-basis: 48%;
        margin-top: 0px; }
        .formModel-dropDown-large {
          flex-basis: 470px; } }
  .formModel-btn-wrapper {
    margin-top: 20px; }
    .formModel-btn-wrapper-big {
      margin-top: 30px;
      display: flex;
      justify-content: center; }
  .formModel-btnSubmit {
    padding: 12px 19px;
    background: #fa6600;
    color: white;
    letter-spacing: -0.16px;
    text-transform: uppercase;
    @apply font-fgs font-normal text-base; }
  .formModel-body {
    width: 100%; }
    .formModel-body-text {
      margin-top: 16px;
      font-size: 12px;
      color: #000;
      @apply font-fga font-normal; }
      .formModel-body-text-limit {
        max-width: 524px; }
    .formModel-body-moto {
      margin-top: 16px;
      width: 100%;
      font-size: 12px;
      @apply font-fga font-medium; }
  .formModel-btn-wrapper {
    margin-top: 20px; }
    .formModel-btn-wrapper .btnSubmit {
      padding: 12px 19px;
      background: #fa6600;
      color: white;
      letter-spacing: -0.16px;
      @apply font-fgs font-normal text-base; }
  .formModel-checkbox {
    margin: 15px 0; }
    .formModel-checkbox-item.error + label {
      color: #a8000a !important; }
    .formModel-checkbox-item.error ~ p.error {
      display: block; }
    .formModel-checkbox p.error,
    .formModel-checkbox div.error {
      padding-left: 35px; }
  .formModel-formPage {
    background: #fff;
    color: #000;
    display: flow-root;
    padding-left: 20px;
    padding-right: 20px;
    @apply py-13; }
    @media (min-width: 768px) {
      .formModel-formPage {
        width: 100%;
        height: 254px;
        max-width: none;
        padding-left: 0px;
        padding-right: 0px; } }
  .formModel-customer-dropDown {
    border: 1px solid #2c2c2c;
    border-radius: 4px;
    width: 100%;
    font-size: 12px;
    color: #aaaaaa;
    line-height: 50px;
    padding: 0 12px;
    background: url("../assets/images/svg/arrow_black.svg") no-repeat center right 12px;
    @apply font-fga font-medium; }
    @media (min-width: 768px) {
      .formModel-customer-dropDown {
        flex-basis: 48%;
        height: 50px; } }
  .formModel_input::placeholder, .formModel_search::placeholder {
    font-size: 12px;
    letter-spacing: -0.12px;
    color: #aaaaaa;
    @apply font-fga font-medium; }
  .formModel_input.error, .formModel_search.error {
    border: 1px solid #a8000a;
    color: #a8000a; }
    .formModel_input.error::placeholder, .formModel_search.error::placeholder {
      color: #a8000a; }
  .formModel_search {
    border: 1px solid #2c2c2c;
    border-radius: 4px;
    height: 50px;
    line-height: 50px;
    padding: 0 12px;
    width: 100%; }
    .formModel_search_logo {
      width: 40px;
      height: 100%;
      position: absolute;
      right: 0;
      display: flex;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid #2c2c2c;
      background-color: white;
      justify-content: center;
      align-items: center;
      border: 1px solid #2c2c2c; }
      .formModel_search_logo img {
        padding: 0;
        width: 13px;
        height: auto; }
  .formModel-overlay {
    top: -99999px;
    position: absolute;
    left: 0px;
    background-color: black;
    width: 100%;
    color: white;
    padding: 15px 20px;
    z-index: 998;
    margin-top: 5px;
    font-size: 12px;
    @apply font-fga font-normal; }
    @media (min-width: 768px) {
      .formModel-overlay {
        width: 600px; } }
  .formModel-location {
    margin-top: 16px;
    display: flex;
    position: relative; }
  .formModel__inner {
    padding-bottom: 40px; }
  .formModel__textbox {
    padding: 10px 0 0; }
    @media (min-width: 960px) {
      .formModel__textbox {
        padding: 0px 0 5px; } }
    .formModel__textbox-title {
      font-size: 12px;
      line-height: 16px;
      padding-top: 16px;
      @apply font-fga font-medium; }
    .formModel__textbox-text {
      font-size: 12px;
      margin-top: 12px;
      @apply font-fga font-normal; }
  .formModel__textbox-inner {
    max-width: 560px;
    padding: 0 30px;
    margin: 0 auto; }
  .formModel__back {
    display: block;
    color: #000;
    font-size: 14px;
    line-height: 18px;
    padding-bottom: 25px;
    font-family: ff(Dincb);
    letter-spacing: 1px; }
    @media (min-width: 960px) {
      .formModel__back {
        display: none; } }
    .formModel__back span {
      margin-left: 12px; }
    .formModel__back .icon {
      height: 16px; }
  @media (min-width: 960px) {
    .formModel .common_sections {
      width: 48%; } }
  @media (min-width: 960px) {
    .formModel .common_sections.special1 {
      width: 500px; } }
  @media (min-width: 960px) {
    .formModel .common_sections.special2 {
      width: 100%; } }
  .formModel__clearBox:after {
    content: " ";
    display: block;
    clear: both; }
  @media (min-width: 960px) {
    .formModel__clearBox .common_sections {
      float: left;
      margin-right: 25px; } }
  .formModel__clearBox .common_sections:nth-child(2n) {
    margin-right: 0px; }
  @media (min-width: 960px) {
    .formModel__clearBox .common_sections.twoLine {
      padding-top: 10px; } }
  .formModel__upAndDown {
    padding: 20px 0 10px;
    font-family: ff(Am); }
  .formModel__litBox {
    margin: -8px 0 10px 0;
    font-size: 14px;
    line-height: 22px;
    opacity: 0.8; }
    @media (min-width: 960px) {
      .formModel__litBox {
        margin-bottom: 4px; } }
  .formModel .recaptchaIpt {
    width: 0;
    height: 0;
    padding: 0;
    outline: 0;
    overflow: hidden;
    border: none; }
  .formModel__sp {
    position: relative;
    margin-top: -8px; }
  .formModel .g-recaptcha iframe {
    width: auto;
    height: auto; }
  .formModel__likeIpt {
    background-color: #fff;
    height: 50px;
    line-height: 50px;
    position: relative;
    padding-left: 10px;
    border: solid 1px;
    border-radius: 4px; }
    .formModel__likeIpt span {
      color: #7e7e7e;
      font-size: 18px; }
  .formModel__calendar {
    position: absolute;
    width: 16px;
    top: 14px;
    right: 16px;
    cursor: pointer; }
  .formModel button.common-cta_btn {
    min-width: 140px; }
    @media (min-width: 960px) {
      .formModel button.common-cta_btn {
        min-width: 210px; } }

.saleForeError {
  color: #f60;
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 10px; }

.common_sections.form_error .formModel__likeIpt {
  color: #a8000a;
  border-color: #a8000a; }

.common_form-loading {
  margin-left: 15px;
  display: none; }

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: 0;
  opacity: 0.01; }

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 35px;
  font-size: 12px;
  line-height: 25px;
  display: block;
  cursor: pointer;
  @apply font-fga font-normal; }

/* checkbox aspect */
[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #2c2c2c;
  background: #fff;
  -webkit-transition: all 0.275s;
  transition: all 0.275s; }

/* checked mark aspect */
[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  font-size: 1.375em;
  top: 1px;
  left: 1px;
  background: #d64529;
  height: 23px;
  width: 23px;
  line-height: 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }

.paragraph__body-mid {
  margin-top: 16px;
  font-size: 12px;
  color: #000;
  @apply font-fga font-normal; }
  .paragraph__body-mid-limit {
    max-width: 524px; }

.clearfix {
  clear: both; }

.popup {
  display: none;
  position: absolute;
  width: calc(100vw - 60px);
  background-color: #333;
  padding: 30px;
  z-index: 3;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  max-width: 744px;
  bottom: 60px;
  font-family: ff(Dor); }
  @media (min-width: 960px) {
    .popup {
      padding: 40px;
      font-size: 16px;
      line-height: 24px; } }
  .popup__close {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer; }
    .popup__close::before, .popup__close::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      border-radius: 2px;
      transform: rotate(45deg);
      background-color: #f7f7f7;
      top: 50%;
      margin-top: -2px; }
    .popup__close::after {
      transform: rotate(-45deg); }
  .popup__footer-traggle {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: #333 transparent transparent;
    left: 20%;
    top: auto;
    bottom: -10px; }

.hero {
  position: relative;
  padding: 80px 0 24px 30px;
  max-width: 764px;
  margin: 0 auto;
  color: #fff; }
  @media (min-width: 960px) {
    .hero {
      padding: 0; } }
  .hero__title {
    font-family: ff(Am);
    font-size: 42px;
    line-height: 48px;
    position: relative;
    z-index: 1; }
    @media (min-width: 960px) {
      .hero__title {
        position: absolute;
        left: calc(100% - 300px);
        top: 85px;
        font-size: 70px;
        line-height: 75px;
        max-width: 300px; } }
  .hero__img {
    position: relative;
    width: 82%;
    max-width: 610px;
    float: right; }
    @media (min-width: 960px) {
      .hero__img {
        float: none; } }
    .hero__img-box {
      margin-top: -67px; }
      .hero__img-box:after {
        content: " ";
        display: block;
        clear: both; }
      @media (min-width: 960px) {
        .hero__img-box {
          margin-top: 0; } }
  .hero__box {
    font-size: 16px;
    line-height: 22px;
    font-family: ff(Din);
    padding: 20px 50px 0 0; }
    @media (min-width: 960px) {
      .hero__box {
        position: absolute;
        left: calc(100% - 700px);
        bottom: 30px;
        max-width: 700px;
        padding: 0; } }
    .hero__box-name {
      font-family: ff(Dinb); }
    .hero__box-par {
      padding-bottom: 20px; }

@media (min-width: 960px) {
  .en_GB .hero__box {
    font-size: 20px;
    line-height: 24px;
    bottom: 5px; } }

@media (min-width: 960px) {
  .de_CH .hero__title,
  .cs_CZ .hero__title,
  .de_AT .hero__title,
  .de_DE .hero__title,
  .fr_BE .hero__title,
  .fr_CA .hero__title,
  .fr_CH .hero__title,
  .fr_FR .hero__title,
  .fr_LU .hero__title,
  .es_ES .hero__title {
    left: calc(100% - 400px);
    top: 85px;
    font-size: 60px;
    line-height: 65px;
    max-width: 400px; } }

.chosen {
  text-align: center;
  @apply font-fga;
  padding-bottom: 60px; }
  .chosen__title h1 {
    font-weight: bold;
    font-size: 37px;
    line-height: 37px;
    letter-spacing: 0px;
    padding-top: 50px;
    padding-bottom: 15px; }
  .chosen__title p {
    font-size: 16px;
    letter-spacing: -0.4px; }
    .chosen__title p:last-child {
      padding-top: 15px;
      padding-bottom: 35px; }
  .chosen__content {
    border-top: 1px solid #eeeceb;
    border-bottom: 1px solid #eeeceb;
    display: flex; }
    .chosen__content-left, .chosen__content-right {
      margin-top: 26px;
      margin-bottom: 30px;
      width: 50%; }
    .chosen__content-left {
      padding-right: 50px;
      text-align: left;
      font-family: Franklin Gothic ATF; }
      .chosen__content-left h2 {
        @apply font-fga font-medium text-base; }
        .chosen__content-left h2 p {
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: -0.4px; }
      .chosen__content-left span,
      .chosen__content-left p {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0px; }
      .chosen__content-left .pt {
        margin-top: 26px; }
      .chosen__content-left .pt6 {
        padding-top: 6px; }
      .chosen__content-left .link {
        margin-top: 20px;
        display: inline-block;
        border: 1.5px solid black;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 36px;
        width: 100%;
        text-align: center;
        letter-spacing: -0.16px;
        @apply font-fgs font-normal; }
        @media (min-width: 768px) {
          .chosen__content-left .link {
            min-width: 132px;
            width: fit-content;
            padding: 0px 17px; } }
    .chosen__content-right {
      padding: 6px 7px 0 7px;
      border: 1px solid #eeeceb; }
      .chosen__content-right img {
        width: 100%;
        height: auto; }
    @media (max-width: 768px) {
      .chosen__content {
        flex-wrap: wrap; }
        .chosen__content-left, .chosen__content-right {
          width: 100%; }
        .chosen__content-left {
          padding-right: 0;
          text-align: center;
          margin-bottom: 0; }
        .chosen__content-right {
          margin-bottom: 20px; } }
  .chosen__body {
    margin-top: 45px;
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .chosen__body {
        justify-content: space-between;
        flex-direction: row; } }
    @media (max-width: 768px) {
      .chosen__body {
        margin-top: 20px; } }
    .chosen__body-left {
      flex-basis: 100%; }
      @media (min-width: 768px) {
        .chosen__body-left {
          flex-basis: 48%; } }
      .chosen__body-left img {
        height: 100%;
        width: 100%;
        object-fit: cover; }
    .chosen__body-right {
      flex-basis: 100%;
      text-align: left;
      display: flex;
      flex-direction: column; }
      @media (min-width: 768px) {
        .chosen__body-right {
          flex-basis: 48%;
          text-align: left; } }
    .chosen__body-title {
      letter-spacing: -0.4px;
      line-height: 20px;
      @apply font-fga;
      font-size: 16px;
      font-weight: 500; }
      @media (max-width: 768px) {
        .chosen__body-title {
          padding-top: 16px;
          text-align: center; } }
    .chosen__body-desc {
      margin-top: 12px;
      font-size: 12px;
      line-height: 16px;
      @apply font-fga font-normal; }
      @media (max-width: 768px) {
        .chosen__body-desc {
          text-align: center; } }
    .chosen__body-link {
      margin: 20px auto 0;
      display: inline-block;
      border: 1.5px solid black;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 36px;
      height: 36px;
      width: 100%;
      text-align: center;
      letter-spacing: -0.16px;
      @apply font-fgs font-normal; }
      @media (min-width: 768px) {
        .chosen__body-link {
          min-width: 132px;
          margin: 20px 0;
          width: fit-content;
          padding: 0px 17px; }
          .chosen__body-link:last-child {
            margin: 0; } }

.dealerCard {
  position: relative;
  max-width: 360px;
  margin: 0 auto; }
  @media (min-width: 960px) {
    .dealerCard {
      float: left; } }
  .dealerCard__title {
    font-family: ff(Am);
    font-size: 26px;
    line-height: 35px;
    padding-bottom: 18px; }
    @media (min-width: 960px) {
      .dealerCard__title {
        padding-bottom: 10px; } }
  .dealerCard__box {
    background-color: #fff;
    padding: 12px 53px 23px 17px; }
    @media (min-width: 960px) {
      .dealerCard__box {
        padding: 24px 75px 51px 42px; } }
    .dealerCard__box-title {
      font-size: 18px;
      line-height: 24px; }
    .dealerCard__box-text {
      font-family: ff(Din);
      font-size: 16px;
      line-height: 22px;
      padding: 10px 0 15px; }
  .dealerCard__link {
    position: absolute;
    bottom: -90px; }
    @media (min-width: 960px) {
      .dealerCard__link {
        position: static;
        margin-top: 40px; } }
    .dealerCard__link .common-cta_btn {
      width: 100%; }

.style__guide {
  background-color: #2c2c2c;
  min-height: 200px; }

.home {
  position: relative;
  background-color: #eeeceb;
  padding-top: 35px;
  padding-bottom: 35px; }
  @media (min-width: 960px) {
    .home {
      padding-top: 35px;
      padding-bottom: 35px; } }
  .home .pageLoad {
    visibility: none; }
  .home__map {
    margin: 0 20px; }
    @media (min-width: 960px) {
      .home__map {
        margin: 0; } }
  .home__form {
    margin: 0 20px; }
    @media (min-width: 960px) {
      .home__form {
        margin: 0; } }

.page-background {
  position: fixed;
  width: 1280px;
  height: 100vh;
  background: #eeeceb;
  background-size: cover;
  top: 0;
  z-index: -1; }

.thanks {
  display: flex; }
  .thanks__box {
    background: #fff;
    max-width: 915px;
    margin: 35px auto; }
    @media (max-width: 768px) {
      .thanks__box {
        margin: 35px 20px; } }
    .thanks__box-inner {
      padding-right: 15.8%;
      padding-left: 15.8%; }
      @media (max-width: 768px) {
        .thanks__box-inner {
          padding: 0 20px; } }

.bg-form {
  width: 100%;
  height: 100%; }
  .bg-form-error {
    width: 100%;
    height: calc(100vh - 242px); }
  @media (min-width: 768px) {
    .bg-form {
      margin-top: 0px; } }

.formdemo-formContainer {
  margin: 35px 20px; }
  @media (min-width: 768px) {
    .formdemo-formContainer {
      width: 100%;
      max-width: 915px;
      margin-left: auto;
      margin-right: auto;
      min-height: calc(100vh - 333px); } }

.formdemo-formWrapper {
  width: 100%;
  background: white;
  position: relative;
  padding-top: 50px;
  padding-bottom: 60px;
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .formdemo-formWrapper {
      min-height: unset;
      max-width: 1280px;
      padding-right: 15.8%;
      padding-left: 15.8%; } }

.formdemo-header {
  text-align: center;
  width: 100%; }
  .formdemo-header h1 {
    line-height: 46px;
    padding-bottom: 15px;
    font-size: 36px;
    font-weight: 700;
    @apply font-fga; }
  .formdemo-header p {
    letter-spacing: -0.4px;
    font-size: 16px;
    @apply font-fga; }

a.formdemo_link {
  color: #d64529;
  display: inline-block;
  position: relative;
  font-size: 16px;
  @apply font-fga; }

@tailwind base;
@tailwind components;
@tailwind utilities;
